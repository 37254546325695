@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Luckiest+Guy&display=swap');

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
  font-family: 'Bebas Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-Bebas * {
  
  font-family: 'Bebas Neue' !important
}

.navbar-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.toggle-icon {
  width: 100%;
  height: 4px;
  background-color: black;
  transition: transform 0.3s ease;
}

.navbar-toggle.open .toggle-icon:nth-child(1) {
  transform: rotate(45deg) translate(6px, 5px);
}

.navbar-toggle.open .toggle-icon:nth-child(2) {
  opacity: 0;
}

.navbar-toggle.open .toggle-icon:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}

.custom-shape-divider-top-1722389171 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1722389171 svg {
  position: relative;
  display: block;
  width: calc(164% + 1.3px);
  height: 402px;
  transform: rotateY(180deg);
}


.custom-shape-divider-top-1722389171 .shape-fill {
  fill: #FFFFFF;
}

.scroll-purple::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scroll-purple::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-purple::-webkit-scrollbar-thumb {
  background-color: #6b21a8; /* Tailwind's purple-700 */
  border-radius: 4px;
}

body::-webkit-scrollbar{
  display: none;
}


.gradient{
  background: radial-gradient(circle, white , rgba(233, 246, 255, 0.428) );
}

 .card{
  font-family: "Bebas Neue";
  position: relative;
  
}

 .card .face{
  width: 300px;
  height: 300px;
  transition: 0.5s;
}

 .card .face.face1{
  position: relative;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
}

 .card:hover .face.face1{
  background: #6f00a6;
  transform: translateY(0);
}

 .card .face.face1 .content{
  opacity: 1;
  transition: 0.5s;
}

 .card:hover .face.face1 .content{
  opacity: 1;
}

 .card .face.face1 .content img{
  max-width: 100%;
}

 .card .face.face1 .content h3{
  margin: 10px 0 0;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 1.5em;
}

 .card .face.face2{
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.8);
  transform: translateY(-100px);
}

 .card:hover .face.face2{
  transform: translateY(0);
}

 .card .face.face2 .content p{
  margin: 0;
  padding: 0;
}

 .card .face.face2 .content a{
  margin: 15px 0 0;
  display:  inline-block;
  text-decoration: none;
  font-weight: 900;
  padding: 5px;
}

.card .face.face2 .content a:hover{
  color: #fff;
}

.brand:after{
  display: flex;
  justify-content: center;
  align-items: center;
  content: attr(data-content);
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 6em;
  line-height: 100%;
  font-weight: 800;
  font-style: italic;
  background: #27272a;
  color: rgba(132, 132, 132, 0.37)
}
.card-button{
  transform: translateY(-20px) scale(3);
  opacity: 0;
}
.card:hover .card-button:first-child {
  
  transition:1s ease-out 100ms;
  transform: translateY(0) scale(3);
  opacity: 1;
}

.card:hover .card-button:last-child {
  transition:1s ease-out 300ms;
  transform: translateY(0) scale(3);
  opacity: 1;
}


.input {
  padding: 10px;
  width: 80px;
  height: 80px;
  border: 4px solid #27272a;
  border-radius: 50px;
  box-sizing: border-box;
  font-size: 26px;
  outline: none;
  transition: .5s;
}
.box:hover .input{
  width: 350px;
  border-radius: 10px;
}
.box .input p{
  transition:0.5s;
}
.box:hover .input p{
  opacity: 1;
}
.box .icon{
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(-50%,-50%) scale(2);
  font-size: 26px;
  color: #27272a;
  transition: .2s;
}
.box:hover .icon{
  opacity: 0;
  z-index: -1;
}

.search .input{
  width: 350px;
  border-radius: 10px;
}

.search .input p{
  opacity: 1;
}

.search .icon{
  opacity: 0;
  z-index: -1;
}

.fade-right {
  opacity: 0;
  animation: fade-right 1500ms ;
  animation-fill-mode: forwards ;
  transition-timing-function:  cubic-bezier(0,0,0,1);
  
}

@keyframes fade-right {
  0% {
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.fade-in {
  animation: fade-in 0.5s ease-in;
  opacity: 0;
  will-change: opacity;
  animation-fill-mode: forwards ;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-up {
  opacity: 0;
  animation: fade-up 1500ms;
  animation-fill-mode: forwards ;
  transition-timing-function:  cubic-bezier(0,0,0,1);
  
}

@keyframes fade-up {
  0% {
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}


.particle {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 60px "Roboto", sans-serif;
}

.particle:before, .particle:after {
  
  top: 50%;
  left: 50%;
  width: 3em;
  height: 3em;
  content: '.';
  mix-blend-mode: screen;
  animation: animate 44s -27s infinite ease-in-out alternate;
}

@keyframes  animate {
  from {
    transform: rotate(0deg) scale(12) translateX(-20px);
  }
  to {
    transform: rotate(360deg) scale(18) translateX(20px);
  }
}

.particle:before {
  text-shadow:
    50px 55px 7px rgba(255, 0, 187, 0.9),
    30px 49px 7px rgba(255, 0, 187, 0.9),
    35px 6px 7px rgba(255, 0, 187, 0.9),
    69px 14px 7px rgba(255, 0, 187, 0.9),
    49px 18px 7px rgba(255, 0, 187, 0.9),
    52px -10px 7px rgba(255, 0, 187, 0.9),
    25px 25px 7px rgba(255, 0, 187, 0.9),
    90px 37px 7px rgba(255, 0, 187, 0.9),
    75px 55px 7px rgba(255, 0, 187, 0.9)
  ;
  animation-duration: 44s;
  animation-delay: -27s;
}

.particle:after {
  text-shadow:
    145px 6px 7px rgba(255, 0, 187, 0.9),
    61px 40px 7px rgba(255, 0, 187, 0.9),
    88px 14px 7px rgba(255, 0, 187, 0.9),
    92px -10px 7px rgba(255, 0, 187, 0.9),
    108px 25px 7px rgba(255, 0, 187, 0.9),
    105px 50px 7px rgba(255, 0, 187, 0.9),
    120px 65px 7px rgba(255, 0, 187, 0.9)
  ;
  animation-duration: 43s;
  animation-delay: -32s;
}

.sm-shadow {
  filter: drop-shadow(-4px 4px 5px rgba(0, 0, 0, 1));
  -webkit-filter: drop-shadow(-4px 4px 5px rgba(0, 0, 0, 1));
}